@import '@styles/theme', '@styles/mixins';

@mixin buttonHover {
  &:not(.disabled) {
    color: $text-primary;
    &::before {
      display: none;
    }
    background-color: $primary;

    svg{
      stroke: $text-primary;
      path{
        stroke: $text-primary;
      }
    }
    &:after {
      border-color: $background-secondary;
    }
    &.secondary {
      color: $text-primary;
    }
    &.light {
      color: $text-secondary;
      border-color: $secondary !important;
      &:after {
        border-color: $secondary !important;
      }
    }
  }
}

.button {
  flex-shrink: 0;
  background-color: $input-box-quaternary;
  position: relative;
  &.secondary {
    background-color: $input-box-primary;
  }
  &.light {
    color: $text-secondary;
    background-color: $secondary;
  }


  color: $text-primary;
  width: 48px;
  height: 48px;
  &.large {
    width: 52px;
    height: 52px;
  }
  position: relative;
  transition: background-color $transition-time-quickest $transition-easing;

  &:active {
    @include buttonHover;
  }

  @include desktop {
    &:hover {
      @include buttonHover;
    }
  }

  &:active {
    &:not(.disabled) {
      &:after {
        left: 0px !important;
        top: 0px !important;
        width: 33px;
        height: 33px;
        border-color: $primary !important;
      }
      &.secondary:after {
        border-color: $text-secondary !important;
      }
      &.light:after {
        border-color: $secondary !important;
      }
    }
  }
  &.large:after {
    width: 49px;
    height: 49px;
    left: 4px;
    top: 4px;
    border-color: $secondary !important;
  }

  &.disabled {
    opacity: 0.3 !important;
    cursor: default;
  }
}
