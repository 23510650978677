@import "@styles/theme", "@styles/mixins", "@styles/animations";

.primary {
  width: 45px;
  height: 45px;
  background-color: #393939;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  svg{
      path{
        stroke: $primary;
      }
    }

  &:hover {
    background-color: $primary;
     svg{
      path{
        stroke: #707070;
      }
    }
  }

  &:active {
    background-color: transparent;
    border: 1px solid #707070;
    svg{
      path{
        stroke: #707070;
      }
    }
  }
}

.secundary {
}

.disable {
  opacity: 0.4;
  cursor: auto;
  &:hover {
    background-color: $primary;
  }
}
