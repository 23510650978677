@import '@styles/theme', '@styles/mixins';

.container {
  overflow: hidden;

  &:not(.noPadding) {
    padding: 14px 20px;
  }

  @include desktop {
    padding-top: inherit;
  }

  position: relative;

  &.hardHeight {
    height: calc(100vh - #{$header-size});

    @include desktop {
      height: 100vh;
    }
  }

  &.softHeight {
    min-height: 550px;

    @include desktop {
      min-height: 100px;
    }
  }
}
