@import '@styles/theme', '@styles/mixins';

.columnDivider {
  max-width: $screen-max-size;
  margin: 0 auto;
}

.container {
  position: relative;
  margin: 0 auto;
  padding-top: $header-size;
  width: 100%;

  @include desktop {
    padding-top: 0;
  }
}
