@import "@styles/theme";
@import "@styles/animations";
@import "@styles/mixins";

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  .previous {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 20%;
    height: calc(100% - 34px);
    z-index: 4;
  }

  .next {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 20%;
    height: calc(100% - 34px);
    z-index: 4;
  }
}

.story {
  position: absolute;
  user-select: none;

  img {
    user-select: none;
  }
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  object-fit: cover;
  object-position: bottom;
  z-index: 0;
}

.story {

  &,
  &>div {
    width: 100%;
    height: calc(100vh - 44px);
  }
}

.clickCta {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  z-index: 2;
  gap: 10px;
  width: 100%;
  top: 24px;
  left: 20px;
  height: 20px;
  color: $default-white;
  font-weight: 200;
  font-size: 10px;

  img {
    margin-right: 5px;
    animation-name: "pointer";
    animation-duration: 7s;
    animation-iteration-count: infinite;
  }
}

.imageHolder {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.scrollCta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 2;
  width: 100%;
  margin-bottom: 8%;

  p {
    color: $text-secondary;
    font-size: 1rem;
    color: $text-secondary;
    font-weight: $font-weight-regular;
  }

  &.scrollCtaHorizontal {
    align-items: center;
    bottom: 10%;
    padding: 0px;
    color: $text-secondary;

    p {
      text-align: center;
      padding-bottom: 12px;
    }
  }

  &.scrollCtaVertical {
    padding: 20px;
    align-items: flex-start;
    bottom: 0;

    p {
      position: absolute;
      left: 0;
      top: 0px;
      text-align: center;
      transform: rotate(-90deg);
      transform-origin: calc(0% + 20px);
      width: fit-content;
      height: 6px;
      padding-left: 15px;
    }
  }

  >div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 12px;

    svg {
      @include animationMargin;

      &:nth-child(1) {
        animation-delay: 0ms;
      }

      &:nth-child(2) {
        animation-delay: 150ms;
      }

      &:nth-child(3) {
        animation-delay: 300ms;
      }
    }
  }

  svg {
    margin-bottom: 12px;
    position: relative;
  }
}

.container {
  position: relative;
  justify-items: start;
  overflow: hidden;
  background-color: $background-primary;
  align-items: start;
  width: 100%;
  height: calc(100vh - #{$header-size});

  //min-height: calc(#{$min-mobile-height} - 44px);
  @media only screen and (device-width: 370px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    min-height: calc(100svh - 6rem);
    /* Estilos específicos para iPhone XR, 11 */
  }
}

.loadinBar {
  width: 100%;
  height: 100%;
  background-color: #fe1c2d;
  animation: loading 1s linear;
}

.nextTopic {
  position: absolute;
  right: toRem(18);
  bottom: 3.5%;
  bottom: 8%;
  color: $text-secondary;
  font-weight: $font-weight-bold;
  font-size: toRem(18);
  z-index: 2;

  svg {
    color: $border-primary;
    margin-left: toRem(9);
    height: toRem(12);
    width: auto;
  }
}

.gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%;
  background: linear-gradient(0deg,
      rgba($background-primary, 1) 0%,
      rgba($background-primary, 0) 100%);
}

.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 20px;
  height: 34px;
  display: flex;
  z-index: 2;
  justify-content: space-between;

  .barItemHolder {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .barItem {
    width: 100%;
    height: 4px;
    background-color: $default-white;
    opacity: 0.9;

    &.hasGone {
      opacity: 1;
      background-color: #fe1c2d;
    }
  }
}

.svgContainer {
  display: flex;
  height: fit-content !important;

  position: absolute;
  padding-left: 53px;
  padding-right: 40px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  bottom: 15%;
  gap: 40px;

  .suaVida {
    width: calc(100% - 115px);
    height: 100%;

    @media (max-width: 380px) {
      width: 40%;
    }

    flex-shrink: 0;
  }


  .lider {
    width: 75px;
    right: 24px;

    flex-shrink: 0;
  }
}

.argoLogo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: clamp(3%, 4%, 5%);
  width: 100%;

  @media (max-width: 380px) {
    top: 2%;
  }
}

.car {
  position: absolute;
  width: 100%;
  height: auto;
  right: -10%;
  bottom: 18%;
  aspect-ratio: 1.1 !important;
}

.titulo {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 8.5%;

  svg {
    width: 85%;
  }

  @media (max-width: 380px) {
    top: 6%;
  }
}