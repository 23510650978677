@import "@styles/theme", "@styles/mixins";

.container {
  width: calc(100% - 60px);
  max-width: 500px;
  min-width: 180px;
  height: calc(100vh - #{$header-size});
  overflow: auto;
  position: fixed;
  z-index: 1000;
  top: $header-size;
  color: $default-white;
  left: -100%;
  box-shadow: 0px 0px 0px 0px transparent;
  background: linear-gradient(to bottom, $primary, $terciary);
  transition: box-shadow, left $transition-time-quick $transition-easing;
  @include desktop {
    color: $text-menu-link;
    background: $background-terciary;
  }

  &.open {
    left: 0;
    box-shadow: 0px 0px 20px 0px rgba($background-dark, 0.5);
  }

  a {
    text-decoration: none;
    font-size: 24px;
    transition: all $transition-time-quick $transition-easing;
    border-bottom: 1px solid $text-secondary;
    line-height: 40px;
    color: $text-secondary;
    font-weight: 400;
    font-style: unset;
    &:not(.menuButton) {
      padding: 13px 0;

    }
    @include desktop {
      color: $text-terciary;
      line-height: 35px;
  
      &:not(.menuButton) {
        padding: 0 20px;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        white-space: nowrap;
        font-size: 16px;
        font-weight: 400 !important;

        &:not(:last-child)::after {
          content: "";
          position: absolute;
          right: 0;
          transform: translate(50%, 50%);
          bottom: 50%;
          height: 20px;
          width: 1px;
          background-color: $separator-primary;
        }
        padding: 0 20px;
        line-height: unset;
      }
    }

    &:not(.menuButton) {
      &:hover,
      &.active {
        color: $text-primary;
        @include desktop {
          color: $text-secondary;
          border-bottom: 2px solid $text-secondary;
        }
        border-bottom: 2px solid $text-primary;
      }
    }
  }

  @include desktop {
    top: 0;
    left: 0;
    height: $nav-height-desktop;
    min-height: 60px;
    transition: none;
    left: 0 !important;
    box-shadow: 0px 0px 0px 0px transparent !important;
    width: 100vw;
    max-width: unset;

    a {
      border: none;
    }
  }
}

.scroller {
  padding: 15px 15px 15px 20px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  @include desktop {
    flex-direction: row;
    padding: 0;

    .logoContainer {
      a{
        text-decoration: none;

        &:hover{
          border: none;
        }
      }
      height: 100%;
      padding: 0 20px;
      position: relative;
      background-color: $default-black;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  position: absolute;
  top: 0;
}

.menuLinks {
  display: flex;
  flex-direction: column;
  @include desktop {
    flex-direction: row;
    align-items: center;
  }
}

.buttonsHolder {
  margin-top: 2.5rem;
  margin-bottom: 6rem;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  @include desktop {
    font-weight: $font-weight-bold;
    margin-bottom: 0rem;
    margin-top: 0rem;
    margin-left: auto;
    margin-right: 20px;
    align-items: center;
    flex-direction: row;
    height: 100%;
    flex: 1;
    justify-content: flex-end;
  }
  .menuButton {

    @include desktop{
      max-width: 200px;
    }


  }
}
.gestureDetector {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 999;
}
