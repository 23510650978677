@import 'theme', 'fonts', 'animations', 'mixins';

html,
body {
  padding: 0;
  margin: 0;
  font-size: 100%;
  line-height: 1.2;
  color: $text-primary;
  background-color: $background-primary;
  font-family: $font;
  width: 100%;
  height: 100%;
  min-width: 320px;
}

#scroll-snap {
  position: relative;
}

html,
#scroll-snap {
  @include desktop {
    scroll-snap-type: none;
  }
}

#__next {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
.column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;
}

a,
.anchor {
  color: $primary;
  font-size: inherit;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

section {
  overflow: hidden;
}

h1 {
  font-size: clamp(2rem, 2.8vw, 4rem);
  font-weight: $font-weight-bold;
}

h2 {
  font-size: clamp(1.125rem, 2vw, 1.7rem);
  font-weight: $font-weight-bold;
}

h3 {
  font-size: clamp(1rem, 1.6vw, 1.3rem);
  font-weight: $font-weight-semibold;
}

p {
  width: 100%;
  font-size: 1rem;
  font-family: $font;
  color: inherit;
  margin: 0;
}

.bullet {
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &.active {
    @include bulletActive($secondary);
  }
  @include desktop {
    &:hover {
      @include bulletActive($secondary);
    }
  }
  span {
    transition: all $transition-time-quick $transition-easing;
    display: block;
    background-color: $bullet-color;
    border-radius: 50%;
    width: 8px;
    height: 8px;
  }
}

.noScroll {
  overflow: hidden !important;
}

strong {
  &.black {
    font-weight: $font-weight-extrablack;
  }

  &.italic {
    font-style: italic;
  }
}

.bringToFront {
  position: relative;
  z-index: 1;
}

span.highlighted {
  background-color: $secondary;
  color: $text-secondary;
  white-space: nowrap;
}

.swiper-slide {
  overflow: hidden;
}

@import 'inputs', 'accordion';

* {
  backface-visibility: hidden;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: $scrollbar-track;
  }
  ::-webkit-scrollbar:vertical {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: $scrollbar-body;
  }
  ::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-body $scrollbar-track;
  box-sizing: border-box;
}
