@import "@styles/theme", "@styles/mixins";

.contentHolder {
  background-color: $input-box-primary;
  color: $text-primary;
  transition: background-color $transition-time-quickest $transition-easing;
  padding: 5px 12px;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
  gap: 10px;
  white-space: nowrap;
  align-items: center;
  position: relative;
  z-index: 1;

  &:hover {
    background-color: $primary;
    border: 1px solid $primary;

    .content {
      background-color: $primary;
      color: $text-primary !important;
    }
  }
  // @include boxShadow($secondary);
}

.footer {
  background-color: $text-primary;
  color: $primary !important;
}

.button {
  position: relative;
  display: block;
  text-decoration: none;
  &.larger {
    .contentHolder {
      padding: 12px 24px;
    }
    .content {
      font-size: 1.2rem;
    }
  }
  border: 1px solid transparent;
  .content {
    // text-align: left !important;
    color: $text-primary;

    .terciary {
      color: pink;
    }
    font-size: 0.9rem;
    &.withIcon:before {
      content: attr(data-icon-code);
      font-family: $fontawesome-brands;
      font-size: 0.9rem;
      margin-right: 3px;
    }
  }
  svg {
    color: $primary;
    flex-shrink: 0;
    position: relative;
    top: 1px;
  }
  &:hover,
  &:active {
    .content {
      color: $text-primary;
    }
    border-color: $primary;
    svg {
      color: $text-primary;
    }
  }

  &:active {
    .bg {
      left: 0px !important;
      top: 0px !important;
      width: calc(100% - 1px);
      height: calc(100% - 1px);
      // border-color: $primary !important;
    }
  }

  &.larger .bg {
    left: 7px;
    top: 7px;
  }

  &.terciary {
    &:hover {
      svg,
      path {
        stroke: $primary;
        color: $primary;
      }
      .bg,
      .contentHolder,
      p {
        background-color: $text-primary;
        border: none;
        color: $primary !important;
      }
    }
  }
  &.secondary {
    .bg {
      border-color: $text-secondary;
    }
    .contentHolder {
      background-color: $input-box-primary;
      color: $text-secondary;

      svg {
        color: $text-primary;
      }
    }
    &:hover,
    &:active {
      .content {
        color: $text-secondary;
        background-color: $text-primary;
      }
      border-color: $text-secondary;
      svg {
        color: $text-secondary;
      }
      .contentHolder {
        background-color: $text-primary;
        color: $text-secondary;

        p {
          color: $text-secondary !important;
        }
      }
    }
  }
}

.terciary {
  background-color: $text-primary;
  border: 1px solid $primary;

  div{
    p{
      font-weight: $font-weight-bold !important;
      font-size: 16px !important;
      font-style: italic !important;
    }
  }
}

.quaternary {
  background-color: #393939;
  .contentHolder {
    background-color: #393939;

    p {
      color: #FDB72B;
    }

    svg {
      color: #FDB72B;
    }

  }

}
