@import "@styles/theme";

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  .previous {
    position: absolute;
    left: #{$section-horizontal-padding-desktop};
    top: 49.5%;
    z-index: 4;
  }
  .next {
    position: absolute;
    right: #{$section-horizontal-padding-desktop};
    top: 49.5%;
    z-index: 4;
  }
}

.story {
  margin-top: 60px;
  position: absolute;
  user-select: none;
  img {
    user-select: none;
  }
}

.video {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  object-fit: cover;
  object-position: bottom;
  z-index: 0;
}

.story {
  &,
  & > div {
    width: 100%;
    height: 100%;
  }
}

.clickCta {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 40px;
  height: 20px;
  img {
    margin-left: 16px;
    animation-name: "pointer";
    animation-duration: 7s;
    animation-iteration-count: infinite;
  }
}

.imageHolder {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  > span > img {
    object-position: 60%;
  }
}
.imageHolder2 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  > span > img {
    object-position: 80%;
  }
}

.container {
  position: relative;
  justify-items: start;
  align-items: start;
  width: 100%;
  height: 100%;
  background-color: $background-primary;
}

.loadinBar {
  animation-name: "loading";
  width: 100%;
  height: 100%;
  background-color: $secondary;
}

.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 20px;
  height: 34px;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  .barItemHolder {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .barItem {
    width: 100%;
    height: 4px;
    &.hasGone {
      background-color: #ffffff30;
    }
  }
}

.bgHolder {
  object-fit: cover;
  img{
    object-fit: cover;
  }
}
