@import '@styles/theme', '@styles/mixins';

.bgClassName {
  height: 40px !important;
}

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  display: flex;
  z-index: -1;
  background-color: $background-secondary;
  box-shadow: 0px #{toRem(-2)} #{toRem(30)} 0px rgba(0, 0, 0, 0.75);
  padding: #{toRem(12)} #{toRem(24)} #{toRem(16)};
  flex-direction: row;
  transition: all $transition-time $transition-easing;

  &.active {
    z-index: 4001;
  }

  p {
    font-size: toRem(14) !important;
  }

  a p:last-child {
    height: 41px;
  }

  a:nth-child(2) {
    margin-left: toRem(20);
  }
}