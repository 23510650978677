@font-face {
  font-family: 'Futura';
  font-weight: 100;
  font-style: normal;
  src: url('fonts/futura/futura_light.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  font-weight: 200;
  font-style: normal;
  src: url('fonts/futura/futura_light.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  font-weight: 300;
  font-style: normal;
  src: url('fonts/futura/futura_light.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  font-weight: 400;
  font-style: normal;
  src: url('fonts/futura/futura_medium.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  font-weight: 600;
  font-style: normal;
  src: url('fonts/futura/futura_bold.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  font-weight: 600;
  font-style: italic;
  src: url('fonts/futura/futura_bold_oblique.otf');
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  font-weight: 700;
  font-style: normal;
  src: url('fonts/futura/futura_bold.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  font-weight: 700;
  font-style: italic;
  src: url('fonts/futura/futura_bold_oblique.otf');
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  font-weight: 800;
  font-style: normal;
  src: url('fonts/futura/futura_bold.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  font-weight: 800;
  font-style: italic;
  src: url('fonts/futura/futura_bold_oblique.otf');
  font-display: swap;
}
@font-face {
  font-family: 'Futura';
  font-weight: 900;
  font-style: normal;
  src: url('fonts/futura/futura_heavy.woff') format('woff');
  font-display: swap;
}

// ABARTH FONTS
@font-face {
  font-family: 'Abarth Light';
  font-weight: 200;
  font-style: normal;
  src: url('fonts/abarth/ABARTH light_0.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Abarth Heavy';
  font-weight: 200;
  font-style: normal;
  src: url('fonts/abarth/ABARTH heavy_0.ttf');
  font-display: swap;
}


@font-face {
  font-family: 'font awesome';
  src: url('fonts/fa/fa-solid-900.ttf'),
    url('fonts/fa/fa-solid-900.woff2') format('woff2'),
    url('fonts/fa/fa-solid-900.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'font awesome brands';
  src: url('fonts/fab/fa-brands-400.ttf'),
    url('fonts/fab/fa-brands-400.woff2') format('woff2'),
    url('fonts/fab/fa-brands-400.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


// NEUE PLAK
@font-face {
  font-family: 'Neue Plak';
  font-weight: 400;
  font-style: normal;
  src: url('fonts/neue/neueplak_regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Neue Plak SB';
  font-weight: 600;
  font-style: normal;
  src: url('fonts/neue/neueplak_semibold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Neue Plak B';
  font-weight: 700;
  font-style: normal;
  src: url('fonts/neue/neueplak_bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Neue Plak BLK';
  font-weight: 800;
  font-style: normal;
  src: url('fonts/neue/neueplak_black.ttf');
  font-display: swap;
}