// BACKGROUNDS PLATES COLORS
$background-primary: #edede3;
$background-secondary: #383838;
$background-terciary: #272727;
$background-dark: #0b0c0d;
$background-grey: #25242f;
$background-active: #343285;
$bullet-color: #cccdce;

// Default Colors
$default-black: #000;
$default-white: #fff;

// BRAND COLORS
$primary: #fdb72b;
$secondary: #fdb72b;
$secondary-light: #fddc9a;
$terciary: #ff1430;
// Alteração da cor para proposto no Wire
// $terciary: #fc1430;

// FONTS COLORS
$text-primary: #393939;
$text-secondary: #fff;
$text-terciary: #ffd47c;
$text-on-primary: #3a369e;
$text-menu-link: #231e1c;
$text-banner-description: #cccccc;
$hr-color: #9fcfd9;

// INPUTS COLORS
$input-box-primary: #fdb72b;
$input-box-secondary: #636363;
$input-box-terciary: #fff;
$input-box-quaternary: #393939;

$border-color: #707070;
$font: "Futura", Roboto, Helvetica Neue, sans-serif;
$font-secondary: Helvetica Neue, Arial, sans-serif;
$fontawesome: "font awesome";
$fontawesome-brands: "font awesome brands";
$scrollbar-track: rgba($primary, 0.23);
$scrollbar-body: $primary;

// BORDERS
$border-primary: $primary;
$border-secondary: $secondary;
$border-size: clamp(8px, 2vh, 18px);
$border-width: 5px;

// SEPARATORS
$separator-primary: #555555;

$min-nav-height-desktop: 60px;

// Abarth
$font-abarth: "FuturaSTD";
$abarth-primary-bg: #fedb00;
$abarth-full-black: #000000;

$section-horizontal-padding: clamp(40px, 4vw, 90px);
$section-horizontal-padding-desktop: clamp(70px, 4vw, 90px);

$min-mobile-height: 568px;

$container-max-width: 1280px;

// FONTS
$font-weight-thin: 100;
$font-weight-ultralight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 800;
$font-weight-extrablack: 900;

$header-size: 44px;
$header-size-desktop: 65px;

$transition-time: 400ms;
$transition-time-quick: 200ms;
$transition-time-quickest: 50ms;
$transition-easing: ease-in-out;

$nav-width: 224px;
$nav-height-desktop: 5vh;

$screen-sm-min: 576.1px;
$screen-md-min: 768.1px;
$screen-lg-min: 992.1px;
$screen-desktop-min: 1024.1px;
$screen-xl-min: 1200.1px;
$screen-xxl-min: 1920.1px;
$screen-max-size: 2560px;
