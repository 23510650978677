@import "@styles/theme", "@styles/mixins", "@styles/animations";

.container {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 28px;

  .cards {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
    gap: 2rem;
    padding-bottom: 2.5%;

    @media (min-width: 1400px) {
      padding-bottom: 3%
    }

    button {
      color: #fff;
      text-transform: uppercase;
      width: fit-content;
      position: relative;
      display: flex;
      padding: 0;
      font-weight: 400;
      font-size: 1rem;
      text-wrap: nowrap;
      transition: 0.2s ease;

      &:hover {
        opacity: 0.8;
      }

      &::after {
        content: "";
        width: 100%;
        position: absolute;
        background:#fff;
        top: -15px;
        height: 1px;
        left: 0;
      }

      &.selected {
        color: #fff;
        font-weight: 700;

        &::before {
          content: "";
          width: 100%;
          position: absolute;
          background: #fff;
          top: 30px;
          height: 1px;
          left: 0;
        }

        &::after {
          content: "";
          width: 100%;
          position: absolute;
          background: #fff;
          // top: 30px;
          height: 1px;
          left: 0;
        }
      }
    }

    .firstCard {
      color: #393939;
      font-weight: 700;

      &::after {
        background:  #393939
      }

      &.selected {
        color: $primary;

        &::before {
          background: $primary;
        }

        &::after {
          background: $primary;
        }
      }

    }
  }

  .anchor {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    font-weight: 400;

    path {
      stroke: #fff;
    }
  }

  .firstCard {
    color: #393939;

    path {
      stroke: #393939;
    }

  }


}