button, input {
  cursor: pointer;
  border: 0;
  display: inline-block;
  text-decoration: none;
  appearance: none;
  background: none;
}

input:disabled {
  user-select: none;
}
